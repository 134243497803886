import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1].replace(/_/g, '/').replace(/-/g, '+')));
    } catch (e) {
        console.log(e)
        return null;
    }
}
 
const ProtectedRoute = ({ children }) => {

    // check if the token is in the url
    // const location = useLocation();
    // const urlParams = new URLSearchParams(location.search);
    const bear_token = window.bear_token;
    if (bear_token) {
        localStorage.setItem('token', bear_token);
        window.bear_token = undefined;
    }
 
   function hasJWT() {
       let flag = false;
 
       //check user has JWT token
    //    localStorage.getItem("token") ? flag=true : flag=false
        // flag = true;
        // check expiration
        const token = localStorage.getItem("token");
        const decoded = parseJwt(token);
        if (decoded && decoded.exp * 1000 > Date.now()) {
            flag = true;
        }
       return flag
   }

   if (!hasJWT()) {
       console.log("no token")
       return <Navigate to='/login'/>
   }
 
   return children
};
 
export default ProtectedRoute;